import React from "react";
import logo from "./daas.png";
import "./App.css";
import Wave from "react-wavify";
import daassejlklub from "./daassejlklub.png";
import mathias from "./mathias.jpg";
import Navbar from "./sections/navbar";
import WaveAnimation from "./wave";
import mathiasportrat from "./mathiasPortræt.jpg";
import boat from "./Daisy_1.jpg";

function App() {
  return (
    <div class="relative bg-white overflow-hidden">
      <Navbar />

      <div className="py-10" style={{ backgroundColor: "#25a9e1" }}>
        <div class="flex flex-row px-10 lg:px-60 my-10 items-center">
          <img
            class="flex flex-col hidden md:inline object-cover w-48 h-48 md:mr-20 rounded-full"
            src={boat}
          />
          <div id="about" class="flex flex-col my-10 text-lg text-white">
            <div class="font-bold text-xl mb-2 ">Om Hyggesejlads</div>
            <p className="mb-6">
              Hyggesejlads handler om at komme ud på vandet, lære nye mennesker
              at kende og få en masse skønne oplevelser og dejlige minder i
              bagagen. Konceptet giver mulighed for enddagsture eller små
              weekendsture på dansk farvand, imens du lærer flere nye mennesker
              at kende.
            </p>
            <p className="mb-6">
              Der er som udgangspunkt mulighed for at komme med ombord som gæst
              på vores ugentlige tirsdagsture. Vores tirsdagsture sejler vi ud
              et par timer fra Marselisborg Havn. Der er afgang kl. 18, så det
              er en god idé at komme lidt før, så vi kan gennemgå en kort
              briefing inden afgang. På vandet laver nogle forskellige manøvre,
              lærer fra hinanden, men vigtigst af alt, hygger os i godt selskab.{" "}
            </p>
            <p className="mb-6">
              En gang om måneden vil vi også planlægge små weekendsture rundt
              omkring i det danske farvand. Disse ture forventes at vare over et
              par dage, og strækker sig typisk over weekender eller helligdage.
              Disse ture koster et beskeden beløb, som dækker kost, brændstof og
              diverse vedligehold.
            </p>
          </div>
          
        </div>
      </div>

      <div class="flex flex-row px-10 lg:px-60 my-10 items-center">
        
        <div id="about" class="flex flex-col my-10 ">
          <div class="font-bold text-xl mb-2">Om Mathias Daa</div>
          Kaptajnen ombord på alle ture er Mathias, som har båden med hans
          forældre. Mathias er 30 års og har altid været vant til livet til søs
          og sejlet i mange år. Han har både speedbådscertifikat, samt
          duelighedsbevis og sejler også kapsejlads ved siden af. Som person er
          han meget imødekommende, udadvendt og elsker at lære nye mennesker at
          kende.
        </div>
        <img
          class="flex hidden md:inline flex-col object-cover w-48 h-48 mx-10 rounded-full"
          src={mathiasportrat}
        />  
      </div>
      <div id="footer" style={{ marginBottom: "-30px" }}>
        <WaveAnimation />
      </div>
    </div>
  );
}

export default App;
