import Wave from "react-wavify";

export default function WaveAnimation() {
  return (
    <div className="">
      <Wave fill="url(#gradient)" className="align-baseline">
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop offset="10%" stopColor="#25a9e1" />
            <stop offset="10%" stopColor="#00BBF9" />
            <stop offset="80%" stopColor="#002E3D" />
          </linearGradient>
        </defs>
      </Wave>
    </div>
  );
}
