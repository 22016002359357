import React from "react";
import logo from "../daas.png";
import "../App.css";
import Wave from "react-wavify";
import daassejlklub from "../daassejlklub.png";
import daisy from "../Daisy_4.jpg";
export default function Navbar() {
  return (
    <div class="relative bg-white overflow-hidden">
      <div class="max-w-7xl ">
        <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <div>
            <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav
                class="relative flex items-center justify-between sm:h-10 lg:justify-start"
                aria-label="Global"
              >
                <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div class="flex items-center justify-between w-full md:w-auto">
                    <a href="#">
                      <span class="sr-only">Hyggesejlads</span>
                      {/* <img
                        alt="Workflow"
                        class="h-8 w-auto sm:h-10"
                        src={logo}
                      /> */}
                    </a>
                    <div class="-mr-2 flex items-center md:hidden">
                      <button
                        type="button"
                        class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        aria-expanded="false"
                      >
                        <span class="sr-only">Åben menu</span>

                        <svg
                          class="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class=" md:block md:ml-10 md:pr-4 md:space-x-8">
                  
                  <a
                    href="#about"
                    class="font-medium text-gray-500 hover:text-gray-900"
                  >
                    Om Hyggesejlads
                  </a>
                  <a
                    href="https://medlem.hyggesejlads.dk/sign-in"
                    class="font-medium text-gray-500 hover:text-gray-900"
                  >
                    Log ind
                  </a>
                </div>
              </nav>
            </div>

            <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <svg
                class="hidden xl:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2 z-900"
                fill="currentColor"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <polygon points="50,0 100,0 50,100 0,100" />
              </svg>
              <div class="sm:text-center">
                <h1 class="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
                <span class="block xl:inline">Hyggesejlads</span>
                <br/>
                    </h1>
                    <h3 class="p-6 text-xl tracking-tight font-bold text-gray-900 sm:text-2xl sm:tracking-tight md:text-2xl md:tracking-tight">

                <span class="block xl:inline">Vi sejler for sjov</span>
                    </h3>

                  <span class="block text-indigo-600 xl:inline">
                    {/* <img
                      alt="Workflow"
                      class="h-48 w-auto mx-20 mb-0"
                      src={daassejlklub}
                    /> */}
                  </span>
                <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  
                </div>
              </div>
            </main>
          </div>
        </div>
        <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            style={{ width: "100%" }}
            src={daisy}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
